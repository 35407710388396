<template>
    <div class="snackbar" :class="{ hidden: !shown, center: position === 'center', right: position === 'right' }">
        <div class="progress-box">  
            <i class="fa fa-spinner fa-spin"></i>  {{ message }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "ProgressSnackBar",
    data: function () {
        return {
            shown: false,
            message: "",
            position: "center",
        };
    },
    
    methods: {
        // To use this component, emit the "progressSnackBegin" event with the message to show.
        // When the task is complete, emit the "progress-snack-end" event.
        show: function (msg: string, position?: string) {
            this.shown = true;
            this.message = msg;
            this.position = position;
        },

        hide: function () {
            this.shown = false;
        },
    },
    mounted: function () {
        this.$listenOnAppEvent("progressSnackBegin", this.show.bind(this));
        this.$listenOnAppEvent("progress-snack-end", this.hide.bind(this));
    },
});
</script>
