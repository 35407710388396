<template>
    <div class="side-bar" :class="{ mini: !expanded }">

        <div class="top-container-side-bar">
            <div class="side-bar-logo-td">
                <div class="logo-container">
                    <img class="logo" src="/svg/incibe-wallet-iot.svg">
                </div>
                <span v-if="expanded" class="side-bar-title">Wallet IoT/IIoT</span>
            </div>
            <div v-if="expanded" class="side-bar-extra-info">
                {{ $t("Digital Wallet for identity credential management for IoT/IIoT devices") }}
            </div>
        </div>
        <button class="side-bar-expand" type="button" @click="clickMenu">
            <i v-if="expanded" class="fa-solid fa-arrow-left"></i>
            <i v-if="!expanded" class="fa-solid fa-arrow-right"></i>
        </button>

        <div class="side-bar-body" :class="{ mini: !expanded }">
            <router-link class="side-bar-option" :class="{ selected: page === 'home' }" :title="$t('Dashboard')"
                :to="{ name: 'home' }">
                <div v-if="expanded" class="side-bar-option-vertical"></div>
                <div class="side-bar-option-icon"><i class="fas fa-home"></i></div>
                <div v-if="expanded" class="side-bar-option-text">{{ $t("Dashboard") }}</div>
            </router-link>

            <router-link class="side-bar-option" :class="{ selected: page === 'devices' }" :title="$t('IoT/IIoT devices')"
                :to="{ name: 'devices' }">
                <div v-if="expanded" class="side-bar-option-vertical"></div>
                <div class="side-bar-option-icon"><i class="fas fa-list"></i></div>
                <div v-if="expanded" class="side-bar-option-text">{{ $t('IoT/IIoT devices') }}</div>
            </router-link>

            <router-link class="side-bar-option" :class="{ selected: page === 'security' }"
                :title="$t('Security Management')" :to="{ name: 'security' }">
                <div v-if="expanded" class="side-bar-option-vertical"></div>
                <div class="side-bar-option-icon"><i class="fas fa-shield"></i></div>
                <div v-if="expanded" class="side-bar-option-text">{{ $t('Security Management') }}</div>
            </router-link>

            <router-link class="side-bar-option" :class="{ selected: page === 'brokers' }" :title="$t('Brokers')"
                :to="{ name: 'brokers' }">
                <div v-if="expanded" class="side-bar-option-vertical"></div>
                <div class="side-bar-option-icon"><i class="fas fa-cloud-upload-alt"></i></div>
                <div v-if="expanded" class="side-bar-option-text">{{ $t('Brokers') }}</div>
            </router-link>

            <router-link class="side-bar-option" :class="{ selected: page === 'identity' }"
                :title="$t('Decentralized Identity')" :to="{ name: 'identity' }">
                <div v-if="expanded" class="side-bar-option-vertical"></div>
                <div class="side-bar-option-icon"><i class="fas fa-user"></i></div>
                <div v-if="expanded" class="side-bar-option-text">{{ $t('Decentralized Identity') }}</div>
            </router-link>

            <router-link class="side-bar-option" :class="{ selected: page === 'blockchain' }"
                :title="$t('Blockchain Network')" :to="{ name: 'blockchain' }">
                <div v-if="expanded" class="side-bar-option-vertical"></div>
                <div class="side-bar-option-icon"><i class="fas fa-link"></i></div>
                <div v-if="expanded" class="side-bar-option-text">{{ $t('Blockchain Network') }}</div>
            </router-link>

            <router-link v-if="isAdmin" class="side-bar-option" :class="{ selected: page === 'request' }" :title="$t('Requests')"
                :to="{ name: 'request' }">
                <div v-if="expanded" class="side-bar-option-vertical"></div>
                <div class="side-bar-option-icon"><i class="fa-solid fa-envelope-open-text"></i></div>
                <div v-if="expanded" class="side-bar-option-text">{{ $t('Requests') }}</div>
            </router-link>
        </div>

        <div class="side-bar-separator" v-if="loggedIn"></div>

        <div class="side-bar-group" v-if="loggedIn">
            <a @click="logout" class="side-bar-close" :title="$t('Log out')">
                <div class="side-bar-option-icon"><i class="fas fa-sign-out-alt"></i></div>
                <div v-if="expanded" class="side-bar-option-text">{{ $t('Log out') }}</div>
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";
import { AuthController } from "@/control/auth";

export default defineComponent({
    components: {},
    name: "MenuSideBar",
    emits: ["toggle-menu", "update:expanded", "openModal"],
    props: {
        expanded: Boolean,
    },
    setup(props) {
        return {
            expandedStatus: useVModel(props, "expanded"),
        };
    },
    data: function () {
        return {
            page: "",
            isAdmin: AuthController.Role === "admin",
            loggedIn: AuthController.isAuthenticated(),
        };
    },
    methods: {
        updatePage: function () {
            this.page = this.$route ? (this.$route.name as string) : "";
        },

        onAuthChanged: function () {
            this.isAdmin = AuthController.Role === "admin";
            this.loggedIn = AuthController.isAuthenticated();
        },

        clickMenu: function () {
            this.$emit("toggle-menu");
        },

        logout: function () {
            this.$emit("openModal", "logout");
        },
    },
    mounted: function () {
        this.updatePage();

        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
    },
    watch: {
        $route: function () {
            this.updatePage();
        },
    },
});
</script>

<style></style>
